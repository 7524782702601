import { LogLevel } from 'angular-auth-oidc-client';

export const config = {
  triggerAuthorizationResultEvent: true,
  postLoginRoute: window.location.origin,
  forbiddenRoute: '/forbidden',
  unauthorizedRoute: window.location.origin + '/Auth',
  logLevel: LogLevel.Debug,
  historyCleanupOff: false,
  authority: "",
  redirectUrl: window.location.origin + '/Auth',
  postLogoutRedirectUri: window.location.origin + '/Auth',
  clientId: "",
  scope: 'openid profile email offline_access',
  responseType: 'code',
  autoUserInfo: false,
  silentRenew: true,
  useRefreshToken: true,
  useSilentRenew: true,
  ignoreNonceAfterRefresh: true,
};