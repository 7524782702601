import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import {
  OpenIdConfiguration,
  StsConfigHttpLoader,
} from 'angular-auth-oidc-client';
import { config } from '../auth/auth.configu';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: any;

  constructor(private http: HttpClient) {}

  // Load config.json at runtime
  loadConfig(): Promise<void> {
    return firstValueFrom(this.http.get('/assets/config.json'))
      .then((config: any) => {
        // console.log('configue',config);
        
        this.config = config;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  httpLoaderFactory(): StsConfigHttpLoader {
    return new StsConfigHttpLoader(
      new Observable<OpenIdConfiguration>((observer) => {
        const configValue = {
          ...config, // Your base configuration
          authority: this.authUrl,
          clientId: this.authClientId,
        };
  
        // Log the config to debug
        // console.log('Loaded config:', configValue,this.apiUrl,this.authUrl,this.authClientId);
  
        if (this.authUrl && this.authClientId) {
          observer.next(configValue);
          observer.complete();
        } else {
          observer.error('Authority or ClientId is missing');
        }
      })
    );
  }
  
  

  // Expose the config values through getters
  get apiUrl(): string {
    return this.config?.apiUrl || '';
  }

  get authUrl(): string {
    // console.log('authUrl', this.config?.authUrl);

    return this.config?.authUrl || '';
  }

  get userApi(): string {
    return this.config?.userApis || '';
  }

  get authClientId(): string {
    // console.log('authClientId', this.config?.clientId);

    return this.config?.clientId || '';
  }
}
