import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, first, last, map, Observable } from 'rxjs';
import { IUser, User } from '../../_models/login.model';
import KcAdminClient from '@keycloak/keycloak-admin-client';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.sefvice';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSb = new BehaviorSubject<Boolean>(false);
  userData = new BehaviorSubject<User | null>(null);
  
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private appConfigService: AppConfigService,    private http:HttpClient
  ) {}
  kcAdminClient = new KcAdminClient({
    baseUrl: this.appConfigService.authUrl,
  });
  isAuth(): Boolean {
    return this.userSb.getValue();
  }

  checkAuthAndLogin(): Observable<void> {

    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        // User is authenticated
        // console.log('User is authenticated');
      } else {
        // User is not authenticated
        // console.log('User is not authenticated');
        this.oidcSecurityService.authorize(); // Redirect to login if not authenticated
      }
    });
    return this.oidcSecurityService.checkAuth().pipe(
      map((auth) => {
        // console.log('auth',auth);
        
        if (!auth) {
          this.login();
        } else {
          this.getUserData();
        }
        this.userSb.next(auth?.isAuthenticated);
      })
    );
  }

  getUserData() {
    this.oidcSecurityService.getIdToken().subscribe((data) => {
      const decodedToken:any = jwtDecode(data);

      if (data) this.updateUserData(decodedToken.sub.split('_')[1]);
    });
  }
  updateUserData(userId: string) {
    this.getUserById(userId).subscribe((data) => {
      this.userData.next(data);
    });
  }
  login() {
    this.oidcSecurityService.authorize();
  }
  async authenticate() {
    await this.kcAdminClient.auth({
      username: 'portal-admin-client',
      password: '123456789',
      grantType: 'password',
      clientId: 'admin-cli',
    });
  }
   updateUserPassword(userId: string, newPassword: string) {
    const userApi = this.appConfigService.userApi 

    return this.http.put<any>(
      userApi + '/users/' + userId + '/reset-password',
      {
        temporary: false,
        value: newPassword,
      }
    );
  }

   updateUserProfile(userId: string, profile: IUser) {
    const userApi = this.appConfigService.userApi 

    return this.http.put<any>(userApi + '/users/' + userId, profile);

  }

  getUserById(userId: string) {
    const userApi = this.appConfigService.userApi 

    return this.http.get<any>(userApi + '/users/' + userId);
  }
  // getUserRoles(): string[] {
  //   let token: string = '';
  //   let decodedToken: any;
  //   this.oidcSecurityService.getIdToken().subscribe((data) => {
  //     token = data;
  //     if (token) {
  //       decodedToken = jwtDecode(token);
  //       console.log(decodedToken);
  //     }
  //   }); // or getAccessToken()
  //   return decodedToken.realm_access.roles;
  // }
}
